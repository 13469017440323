import * as React from "react";
// import styled from "styled-components";
import { useInterpolatedString, useVariable } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { Button } from "@smartphoneorchestra/components";

interface ColorProps {
  color: string;
  showLogo?: boolean;
}

// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const Color = (props: ColorProps) => {
  const color = useInterpolatedString(props.color);
  const showLogo = useVariable<boolean>(props.showLogo, false);
  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("color");
  }, [color, showLogo]);
  React.useEffect(() => {
    document.body.style.backgroundColor = color;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [color]);
  // return <Container>{showLogo && <div className={"logo"} />}</Container>;
  return <Button>Hello!</Button>;
};

export default Color;
