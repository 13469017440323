import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./toucan/src/store";
import FirebaseImpl from "./toucan/src/firebase";
import "./Styles/theme.css";

import "./toucan/src/devices/stater";
import { Tone } from "@smartphoneorchestra/live.js";

import InterfaceManager from "./toucan/src/interfaces/manager";
import * as Requests from "./toucan/src/requests";

import Action from "./Action";
import Questions from "./Questions";
import Color from "./Color";
import Image from "./Image";
import VoteButtons from "./VoteButtons";
import TextSlider from "./TextSlider";
import Gate from "./Gate";
import ColorText from "./ColorText";
import CustomText from "./CustomText";
import PersonalityGate from "./PersonalityGate";
import EndInstructions from "./EndInstructions";
import CustomSoundText from "./CustomSoundText";
import CircleGate from "./CirlceGate";
import TitleScreen from "./TitleScreen";
import TheCharm from "./TheCharm";
import LastInstructions from "./LastInstructions";
import TextWithFooter from "./TextWithFooter";
import Playing from "./Playing";
import Countdown from "./Countdown";
import Ready from "./Ready";
import Syncing from "./Syncing";
import Waiting from "./Waiting";
import { default as ToucanText } from "./Text";
import { playNotification } from "./sound";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen } from "./Styles/StyleComponents";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://2c881986fb524f1bb7b35ae4b72e00f2@o395783.ingest.sentry.io/6372105",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.25,
  });
}

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    ns: ["toucan", "thecircleoftruth"],
    backend: {
      projectId: "1882e170-35f1-447f-9b1e-ebe5c9474b0b",
      referenceLng: "en",
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "thecircleoftruthLng",
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
    },
  });

FirebaseImpl.initialize({
  apiKey: "AIzaSyAetO-O88y2ZO0GTHRZjrG4x6t3IMf6LUU",
  databaseURL:
    "https://the-circle-of-truth-default-rtdb.europe-west1.firebasedatabase.app/",
  localStorageUUID: "thecircleoftruth",
});

InterfaceManager.registerInterface("action", Action);
InterfaceManager.registerInterface("questions", Questions);
InterfaceManager.registerInterface("color", Color);
InterfaceManager.registerInterface("image", Image);
InterfaceManager.registerInterface("votebuttons", VoteButtons);
InterfaceManager.registerInterface("textslider", TextSlider);
InterfaceManager.registerInterface("gate", Gate);
InterfaceManager.registerInterface("colortext", ColorText);
InterfaceManager.registerInterface("customtext", CustomText);
InterfaceManager.registerInterface("personalitygate", PersonalityGate);
InterfaceManager.registerInterface("endinstructions", EndInstructions);
InterfaceManager.registerInterface("customsoundtext", CustomSoundText);
InterfaceManager.registerInterface("circlegate", CircleGate);
InterfaceManager.registerInterface("titlescreen", TitleScreen);
InterfaceManager.registerInterface("thecharm", TheCharm);
InterfaceManager.registerInterface("lastinstructions", LastInstructions);
InterfaceManager.registerInterface("textwithfooter", TextWithFooter);
InterfaceManager.overrideFixedComponent("playing", Playing);
InterfaceManager.overrideFixedComponent("countdown", Countdown);
InterfaceManager.overrideFixedComponent("ready", Ready);
InterfaceManager.overrideFixedComponent("syncing", Syncing);
InterfaceManager.overrideFixedComponent("waiting", Waiting);
InterfaceManager.registerInterface("text", ToucanText);

Tone.Transport.PPQ = 48;

InterfaceManager.setOnComponentChangedCallback(() => {
  playNotification();
});

Requests.setCompositionUrl(
  "//conductor.smartphoneorchestra.com/set/9eAK1I1xqwEWLoh6rPtD"
);

const SuspenseFallback = () => (
  <Screen align="center" color="pink">
    <Stack grow justify="center">
      <Text center color="secondary">
        Loading...
      </Text>
    </Stack>
  </Screen>
);

ReactDOM.render(
  <React.Suspense fallback={<SuspenseFallback />}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </React.Suspense>,

  document.getElementById("root")
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
document
  .getElementsByTagName("body")[0]!
  .addEventListener("click", async () => {
    if (Tone.context.state === "running") {
      return;
    } else if (Tone.context.state === "suspended") {
      await Tone.context.resume();
      console.log("Tone context state is now:", Tone.context.state);
      return;
    }
    await Tone.start();
    console.log("Tone context state is now:", Tone.context.state);
  });
