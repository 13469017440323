import * as React from "react";
import Option from "./Option";
import * as Types from "../types";
import { useTranslation } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";

interface QuestionProps {
  question: Types.Question;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChoice: (choice: Types.Option) => void;
}

const Question = (props: QuestionProps) => {
  const { t } = useTranslation("thecircleoftruth");

  return (
    <Stack>
      <Text center color="primary" size="4">
        {t(props.question.question)}
      </Text>

      <Stack justify={"center"}>
        {props.question.options.map((option, index) => (
          <Option
            key={`key-${index}`}
            option={option}
            onClick={props.onChoice}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Question;
