import * as React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "./toucan/src/store";

import { Stack } from "@smartphoneorchestra/components";
import { Screen } from "./Styles/StyleComponents";

const Countdown = () => {
  const countdownTime = useSelector((s: IAppState) => s.countdownTime);
  const seconds = Math.min(-Math.floor(countdownTime), 10);
  return (
    <Screen align="center">
      <Stack grow justify="center">
        <div className={"countdown"}>{seconds}</div>
      </Stack>
    </Screen>
  );
};

export default Countdown;
