import * as React from "react";
import { Text, Stack, Button } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

interface ResultProps {
  onAccept: () => void;
  onPrevious: () => void;
}

const Result = ({ onAccept, onPrevious }: ResultProps) => (
  <Screen align="center" color="pink">
    <TruthAnalyticsLogo width="50%" />

    <Stack grow align="center" justify="center" style={{ width: "100%" }}>
      <img src="/images/answers.svg" alt="" style={{ maxWidth: "50%" }} />
      <Text color="primary" center>
        Are you ready to lock in your answers?
      </Text>
    </Stack>

    <Stack>
      <Button onClick={onAccept}>Lock In</Button>
      <Button bordered onClick={onPrevious}>
        Previous
      </Button>
    </Stack>
  </Screen>
);

export default Result;
