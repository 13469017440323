import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { useTranslation } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface CustomTextProps {
  text: string;
}

const CustomText = (props: CustomTextProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("text");
  }, [text]);

  return (
    <Screen align="center" color="blue">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Text center color="primary">
          {text}
        </Text>
      </Stack>
    </Screen>
  );
};

export default CustomText;
