import * as React from "react";

import { Pile } from "@smartphoneorchestra/components";
import { Screen } from "./Styles/StyleComponents";

const AudioReactiveCanvasImage = () => (
  <Screen align="center" background="pink-light" color="black">
    <Pile justify="center">
      <img src="/images/symbols.svg" alt="" width={"1000%"} />
    </Pile>
  </Screen>
);

const Playing = () => <AudioReactiveCanvasImage></AudioReactiveCanvasImage>;

export default Playing;
