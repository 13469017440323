export function isValidUsername(text: string) {
  return (
    typeof text === "string" &&
    text.length > 0 &&
    text.length <= 20 &&
    !text.match(/[ .$[\]#/]/)
  );
}

export function isValidBirthyear(text: string) {
  const number = parseInt(text, 10);
  const now = new Date().getFullYear();
  return (
    typeof text === "string" &&
    text.length === 4 &&
    text.match(/^[0-9]+$/) != null &&
    number > now - 110 &&
    number < now - 10
  );
}
