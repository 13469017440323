import { Tone } from "@smartphoneorchestra/live.js";
import { randomElement } from "./toucan/src/utils";

const notificationPlaybackRate = [1, 1.498307, 1.122462, 1.587401];

const notificationPlayer = new Tone.Player(
  "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/circleoftruth/Notification-F-Vib.mp3"
).toDestination();

const customSamplePlayer: Record<string, any> = {
  HarpTheme: new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/circleoftruth/HarpThem-Korter.mp3"
  ).toDestination(),
  HarpThemeVib: new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/circleoftruth/HarpThem-Korter-Vib.mp3"
  ).toDestination(),
};

const bliepPlaybackRate = [0.5, 1.0, 1.5, 2];

const bliepPlayers = [
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Ab.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Bb.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-C.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-Eb.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-1-G.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Ab.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Bb.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-C.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-Eb.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/blieps/BLIEP-2-G.mp3"
  ).toDestination(),
];

function audioChecks(player: Tone.Player) {
  if (Tone.context.state !== "running" || !player.loaded) {
    return;
  }
  if (player.state === "started") {
    player.restart(Tone.now() + 0.1);
  } else {
    player.start(Tone.now() + 0.1);
  }
}

export function playNotification() {
  notificationPlayer.playbackRate = randomElement(notificationPlaybackRate);
  audioChecks(notificationPlayer);
}

export function playCustomSample(sample: string, vol?: number) {
  const sampleToPlay = customSamplePlayer[sample];
  sampleToPlay.volume.value = vol ? vol : -12;
  audioChecks(sampleToPlay);
}

export function playRandomSound() {
  const randomPlayer = randomElement(bliepPlayers);
  randomPlayer.playbackRate = randomElement(bliepPlaybackRate);
  audioChecks(randomPlayer);
}
