import * as React from "react";
import MainframeConnector, {
  IFirebaseState,
} from "./toucan/src/firebase/mainframe";
import { store } from "./toucan/src/store";
import { useFirebaseUserState } from "./toucan/src/firebase/hooks";
// import useCompositionStaterInterface from "./StaterToCompositionInterface";

MainframeConnector.on("change", (data) => {
  const { startTime, state } = data as IFirebaseState;
  store.dispatch({ type: "", startTime, state });
});

// start observing performance state once we have our offset
store.subscribe(() => {
  const { offset } = store.getState();
  if (offset !== undefined) {
    MainframeConnector.observeState();
  }
});

export function useInterfaceManager() {
  const [toucanView, setToucanView] = React.useState("loading");
  // const staterInterface = useCompositionStaterInterface(0);

  // listen to toucan's state
  React.useEffect(() => {
    store.subscribe(() => {
      const { view } = store.getState();
      setToucanView(view);
    });
  }, [setToucanView]);

  // //hack in the stater interface state
  // const toucanInterfaceView =
  //   staterInterface === "toucan" ? toucanView : staterInterface;

  const [userInterface] = useFirebaseUserState<string>(`interface`);
  const hasCustomInterface = userInterface !== "toucan";
  return hasCustomInterface ? userInterface : toucanView;

  // return hasCustomInterface ? userInterface : toucanInterfaceView;
}
