import * as React from "react";

import { useTranslation } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

const AllSet = () => {
  const { t } = useTranslation("thecircleoftruth");
  const text = t("all-set");

  return (
    <Screen align="center" color="pink">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Text center color="primary">
          {text}
        </Text>
      </Stack>
    </Screen>
  );
};

export default AllSet;
