import * as React from "react";
import { useTranslation } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

const Syncing = () => {
  const { t } = useTranslation("toucan");
  return (
    <Screen align="center">
      <Stack grow justify="center">
        <TruthAnalyticsLogo width="100%" />
        <Text center color="primary">
          {t("synchronising")}
        </Text>
      </Stack>
    </Screen>
  );
};

export default Syncing;
