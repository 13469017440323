import * as React from "react";
import { playCustomSample } from "./sound";

import { Stack, Text } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

import Logo from "./toucan/src/images/Logo.svg";
import styled from "styled-components";

interface TitleScreenProps {
  sample: string;
  vol: number;
}

const LogoStyled = styled.div`
  .logo {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 85%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-origin: content-box;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
  }
`;

const TitleScreen = (props: TitleScreenProps) => {
  const sample = props.sample;
  const volume = props.vol;

  React.useEffect(() => {
    playCustomSample(sample, volume);
  }, [sample]);

  return (
    <Screen align="center" color="blue">
      <Stack grow gap="7" justify="center">
        <TruthAnalyticsLogo width="100%" />
        <Text center={true}>by</Text>
        <LogoStyled>
          <img src={Logo} className="logo" alt="logo" />
        </LogoStyled>
      </Stack>
    </Screen>
  );
};

export default TitleScreen;
