import * as React from "react";
import { useTranslation } from "react-i18next";
import { Update } from "../toucan/src/firebase/mutations";
import { isValidBirthyear } from "../utils";

import { Button, Stack, Input, Text } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

const Profile = () => {
  const [birthyear, setBirthyear] = React.useState("");
  const { t } = useTranslation("thecircleoftruth");

  const usernameisValid = birthyear.length === 0 || isValidBirthyear(birthyear);
  const nextEnabled = birthyear !== "" && usernameisValid;

  const usernameChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) =>
    setBirthyear(e.currentTarget.value.trim());

  const saveProfile = () => {
    const update = new Update();
    const interfaceName = "go_to_circle_gate";
    const birthdate = {
      year: birthyear,
    };
    update.updateUser({ birthdate, interface: interfaceName });
    update.execute();
  };

  return (
    <Screen align="center" color="green">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Input
          value={birthyear}
          onChange={usernameChangeEvent}
          placeholder={"Your year of birth"}
          // valid={usernameisValid.toString()}
        />
      </Stack>
      <Button disabled={!nextEnabled} onClick={() => saveProfile()}>
        <Text className="question__answer_text">{t("done")}</Text>
      </Button>
    </Screen>
  );
};

export default Profile;
