import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { useTranslation } from "react-i18next";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface TextWithFooter {
  text: string;
  footer: string;
}

const TextWithFooter = (props: TextWithFooter) => {
  const { t } = useTranslation("thecircleoftruth");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);
  const footerTransText = t(props.footer);
  const footerText = footerTransText;

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("text");
  }, [text]);

  return (
    <Screen align="center" color="blue">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Text center color="primary">
          {text}
        </Text>
        <Text center color={"normal"} size="2">
          {footerText}
        </Text>
      </Stack>
    </Screen>
  );
};

export default TextWithFooter;
