import * as React from "react";
import firebase from "../toucan/src/firebase";
import { Update } from "../toucan/src/firebase/mutations";
import { userIdWithUsername, migrateUserToSelf } from "../database";
import UsernamePassword from "./UsernamePassword";
import { useTranslation } from "react-i18next";

import { Text, Stack, Button } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

type ProfileManagerState = undefined | "create" | "login";

interface ProfileButtonsProps {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFlow: (state: ProfileManagerState) => void;
}

const ProfileButtons = (props: ProfileButtonsProps) => {
  const { t } = useTranslation("thecircleoftruth");

  return (
    <Screen align="center" justify="space-between" color="pink">
      <img
        src="/images/smartphoneorchestra-logo.svg"
        alt="The Smartphone Orchestra"
        width="70%"
      />
      <Text>Presents:</Text>
      <TruthAnalyticsLogo />
      <Text center bold color="primary">
        {t("unmute-phone")}
      </Text>
      <Stack>
        <Button
          style={{ color: "var(--surface-1)" }}
          onClick={() => props.setFlow("create")}
        >
          {t("join")}
        </Button>
        <Button onClick={() => props.setFlow("login")} bordered>
          {t("coming-back")}
        </Button>
      </Stack>
    </Screen>
  );
};

const Login = () => {
  const { t } = useTranslation("thecircleoftruth");

  const [hasError, setHasError] = React.useState(false);
  const [flow, setFlow] = React.useState<ProfileManagerState>(undefined);

  const onSignup = React.useCallback(async (username: string) => {
    const user = await userIdWithUsername(username);
    if (user) {
      setHasError(true);
      return;
    }

    const update = new Update();
    update.updateUser({ username });
    update.updateKey(`usernames`, { [username]: firebase.uuid() });
    update.execute();
  }, []);

  const onSignin = React.useCallback(async (username: string) => {
    const user = await userIdWithUsername(username);
    if (!user) {
      setHasError(true);
      return;
    }

    migrateUserToSelf(user);
  }, []);

  if (flow === "create") {
    return (
      <UsernamePassword
        hasError={hasError}
        onAccept={onSignup}
        submitText={t("join")}
        isLogin={true}
      />
    );
  } else if (flow === "login") {
    return (
      <UsernamePassword
        hasError={hasError}
        onAccept={onSignin}
        submitText={t("rejoin")}
        isLogin={false}
      />
    );
  } else {
    return <ProfileButtons setFlow={setFlow} />;
  }
};

export default Login;
