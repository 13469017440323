import * as React from "react";
import { actions } from "./toucan/src/store";
import { useTranslation } from "react-i18next";

import { Text } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

const Ready = () => {
  const { t } = useTranslation("thecircleoftruth");
  const url = "/images/Fingerprint.svg";

  return (
    <Screen
      align="center"
      justify="space-between"
      color="green"
      onClick={actions().startSync}
    >
      <TruthAnalyticsLogo width="50%" />
      <img src={url} alt="" style={{ width: "30%" }} />
      <Text center color="primary">
        {t("press-play")}
      </Text>
    </Screen>
  );
};

export default Ready;
