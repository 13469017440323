import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { useTranslation } from "react-i18next";

import { Text, Button, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface CircleGateProps {
  text: string;
  buttonText: string;
  nextInterface: string;
}

const CircleGate = (props: CircleGateProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);
  const circleText = useInterpolatedString("${this.user.position}");
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("gate");
  }, [text]);

  return (
    <Screen align="center">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow align="center" fullWidth justify="center" gap="7">
        <img src="/images/circles.svg" alt="" width="70%" />
        <Stack align="center" gap="1">
          <Text color="primary">{text}</Text>
          <Text color="primary" size="6">
            {"CIRCLE: " + circleText}
          </Text>
          <Button onClick={onClick}>{t(props.buttonText)}</Button>
        </Stack>
      </Stack>
    </Screen>
  );
};

export default CircleGate;
