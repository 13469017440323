import * as React from "react";
import { useInterfaceManager } from "./InterfaceManager";
import Login from "./Login";
import AllSet from "./Login/AllSet";
import Profile from "./Login/Profile";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useFirebaseUserState } from "./toucan/src/firebase/hooks";
import ComponentRenderer from "./toucan/src/interfaces";
import { actions } from "./toucan/src/store";
import { UserData } from "./types";

const App = () => {
  const [user] = useFirebaseUserState<UserData>("/");
  const currentInterface = useInterfaceManager();

  React.useEffect(() => {
    actions().requestPart();
  }, []);

  React.useEffect(() => {
    document.body.className = currentInterface || "";
    document.body.scrollTop = 0;
  }, [currentInterface]);

  if (user?.username === undefined) {
    return <Login />;
  }

  if (!user.birthdate) {
    return <Profile />;
  }

  if (user.participating === undefined) {
    return <AllSet />;
  }

  if (user.participating === false) {
    return <GenericLayout mainText="We're full, sorry!" />;
  }

  return <ComponentRenderer interface={currentInterface} />;
};

export default App;
