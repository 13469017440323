import * as React from "react";
import * as Types from "../types";
// import { Button } from "./Elements";
import { useTranslation } from "react-i18next";

import { Button, Text } from "@smartphoneorchestra/components";

interface OptionProps {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick: (value: Types.Option) => void;
  option: Types.Option;
}

const Option = ({ option, onClick }: OptionProps) => {
  const { t } = useTranslation("thecircleoftruth");

  return (
    <Button onClick={() => onClick(option)}>
      <Text className="question__answer_text" size="3">
        {t(option.text)}
      </Text>
    </Button>
  );
};

export default Option;
