import firebase from ".";
import * as database from "firebase/database";

export class Update {
  private update: Record<string, unknown> = {};

  public updateUser(data: Record<string, unknown>): void {
    Object.keys(data).forEach((key) => {
      this.update[`users/${firebase.uuid()}/${key}`] = data[key];
    });
  }

  public updateKey(key: string, data: Record<string, unknown> | null): void {
    if (data === null) {
      this.update[key] = data;
    } else {
      Object.keys(data).forEach((dataKey) => {
        this.update[`${key}/${dataKey}`] = data[dataKey];
      });
    }
  }

  public execute(): void {
    database.update(firebase.root(), this.update);
  }
}
