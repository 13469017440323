import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface ITextProps {
  text: string;
}

const Text = (props: ITextProps) => {
  const text = useInterpolatedString(props.text);
  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("text");
  }, [text]);
  return <GenericLayout contentClass="text" mainText={<h2>{text}</h2>} />;
};

export default Text;
