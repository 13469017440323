import * as React from "react";
import { isValidUsername } from "../utils";
import { useTranslation } from "react-i18next";

import { Stack, Button, Input, Text } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

interface UsernamePasswordProps {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAccept: (username: string) => void;
  submitText: string;
  hasError: boolean;
  isLogin: boolean;
}

interface ErrorProps {
  loginType: boolean;
}

const Error = (props: ErrorProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const errorText = props.loginType ? "name-error" : "rejoin-error";

  return (
    <Text center color="secondary">
      {t(errorText)}
    </Text>
  );
};

const UsernamePassword = ({
  onAccept,
  submitText,
  hasError,
  isLogin,
}: UsernamePasswordProps) => {
  const [username, setUsername] = React.useState("");
  const { t } = useTranslation("thecircleoftruth");

  const usernameisValid = username.length === 0 || isValidUsername(username);
  const nextEnabled = username !== "" && usernameisValid;

  const usernameChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.currentTarget.value.trim());

  const acceptEvent = () => onAccept(username);

  return (
    <Screen align="center" color="blue">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        {hasError && <Error loginType={isLogin} />}
        <Input
          value={username}
          onChange={usernameChangeEvent}
          placeholder={t("name")}
          //valid={usernameisValid.toString()}
        />
      </Stack>
      <Button disabled={!nextEnabled} onClick={acceptEvent}>
        {submitText}
      </Button>
    </Screen>
  );
};

export default UsernamePassword;
