import firebase from "./toucan/src/firebase";
import { Update } from "./toucan/src/firebase/mutations";
import { get, child, orderByKey, equalTo, query } from "firebase/database";

export async function userIdWithUsername(username: string) {
  const queryRef = query(
    query(child(firebase.root(), "usernames"), orderByKey()),
    equalTo(username)
  );
  const snapshotVal = (await get(queryRef)).val();
  return snapshotVal ? (snapshotVal[username] as string) : undefined;
}

export async function passwordOfUser(userId: string) {
  const snapshot = await get(
    child(firebase.root(), `users/${userId}/password`)
  );
  return snapshot.val();
}

export async function migrateUserToSelf(uuid: string) {
  const snapshot = await get(child(firebase.root(), `users/${uuid}`));
  const update = new Update();
  const data = snapshot.val();
  update.updateUser(data);
  update.updateKey(`usernames`, { [data.username]: firebase.uuid() });
  update.updateKey(`users/${uuid}`, null);
  update.execute();
}
