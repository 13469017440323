import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import GenericLayout from "./toucan/src/components/GenericLayout";

interface ColorTextProps {
  color: string;
  text: string;
}

const ColorText = (props: ColorTextProps) => {
  const color = useInterpolatedString(props.color);
  const text = useInterpolatedString(props.text);

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("color");
  }, [color, text]);
  React.useEffect(() => {
    document.body.style.backgroundColor = color;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [color]);

  return <GenericLayout contentClass="text" mainText={<h1>{text}</h1>} />;
};

export default ColorText;
