import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";
import { playCustomSample } from "./sound";

import { Text, Button, Stack } from "@smartphoneorchestra/components";
import { Screen } from "./Styles/StyleComponents";

interface GateProps {
  nextInterface: string;
  sample: string;
  vol: number;
}

const PersonalityGate = (props: GateProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const personalityText = useInterpolatedString("${this.user.text}");
  const text = t(personalityText);
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");
  const personalityColor = useInterpolatedString("${this.user.color}");
  const sample = props.sample;
  const volume = props.vol;
  const footerText = t("screenshot-text");

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = personalityColor;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [personalityColor]);

  React.useEffect(() => {
    playCustomSample(sample, volume);
  }, [volume, sample, text]);

  return (
    <Screen align="center" background={personalityColor} color="black">
      <Stack grow justify="center">
        <Text center size="4" underline color="primary">
          {t("personal-message")}
        </Text>
        <Text center color="primary" size="3">
          {text}
        </Text>
      </Stack>
      <Text center color={"normal"} size="2">
        {footerText}
      </Text>
      <Button onClick={onClick}>Next</Button>
    </Screen>
  );
};

export default PersonalityGate;
