import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";

import { Text, Button, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface GateProps {
  nextInterface: string;
}

const LastInstructions = (props: GateProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");
  const personalityColor = useInterpolatedString("${this.user.color}");
  const personalityGroup = useInterpolatedString("${this.user.group}");

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  const texts = ["turn-off-phone", "belongings", "shut-locker"];

  const ToDoList = () => {
    const listItems = texts.map((text) => <li key={"list"}>{t(text)}</li>);
    return <ol>{listItems}</ol>;
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = personalityColor;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [personalityColor]);

  return (
    <Screen align="center" background={personalityColor} color="black">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Text center size="4" underline color="primary">
          {t(personalityGroup)}
        </Text>
        <Text center color="primary" size="3">
          <ToDoList></ToDoList>
        </Text>
      </Stack>
      <Button onClick={onClick}>Back</Button>
    </Screen>
  );
};

export default LastInstructions;
