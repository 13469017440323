import * as React from "react";
import Question from "./Question";
import * as Types from "../types";
import Result from "./Result";
import {
  useFirebaseTargetState,
  useFirebaseUserState,
} from "../toucan/src/firebase/hooks";
import * as Sound from "../sound";
import { Update } from "../toucan/src/firebase/mutations";

import { Button } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "../Styles/StyleComponents";

interface QuestionsProps {
  target: string;
}

const Questions = (props: QuestionsProps) => {
  const [userQuestions, setUserQuestions] =
    useFirebaseUserState<Types.Question[]>("questions");

  // answers in the user's key. We only flush them to the target once
  // the user accepts their answers
  const [userAnswers, setUserAnswers] =
    useFirebaseUserState<string[]>("answers");
  const questions = userQuestions || [];
  const [, setFinalAnswers] = useFirebaseTargetState<string[]>(props.target);
  const questionProgress = userAnswers?.length || 0;
  const [questionIndex, setQuestionIndex] = React.useState(0);

  React.useEffect(() => {
    Sound.playNotification();
  }, []);

  //make sure that after a refresh the user doesnt have to start over
  React.useEffect(() => {
    setQuestionIndex(questionProgress);
  }, [userAnswers]);

  const onAccept = () => {
    // Sound.playRandomSound();
    setFinalAnswers(userAnswers || []);
    setUserQuestions([]);
    setUserAnswers([]);

    const update = new Update();
    update.updateUser({ interface: "questions_done_gate" });
    update.execute();
  };

  const onClick = (choice: Types.Option) => {
    // Sound.playRandomSound();
    const copy = userAnswers || [];
    copy[questionIndex] = choice.value;
    setQuestionIndex(questionIndex + 1);
    setUserAnswers(copy);
  };

  const onPrevious = () => {
    const currentIndex = Math.min(questionIndex, questions.length);
    setQuestionIndex(currentIndex - 1);

    const copyWithoutLastAnswer = userAnswers?.slice(0, -1) || [];
    setUserAnswers(copyWithoutLastAnswer);
  };

  // if (!Array.isArray(questions) || questions.length === 0) {
  //   return <GenericLayout footerText={<span>{t("end-questions")}</span>} />;
  // }

  if (questionIndex >= questions.length) {
    return <Result onAccept={onAccept} onPrevious={onPrevious} />;
  } else {
    return (
      <Screen align="center" color="green">
        <TruthAnalyticsLogo width="50%" />
        <div style={{ padding: "0.1rem" }}></div>
        <Question question={questions[questionIndex]} onChoice={onClick} />
        {questionIndex > 0 && (
          <Button bordered={true} onClick={onPrevious}>
            Previous
          </Button>
        )}
      </Screen>
    );
  }
};

export default Questions;
