import * as React from "react";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { useTranslation } from "react-i18next";
import { useInterpolatedString } from "./toucan/src/interpolate";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface ImageProps {
  url: string;
  text: string;
  color: string;
  footerText?: string;
}

const Image = (props: ImageProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);
  const url = props.url;
  const color = props.color;

  const footerText = t(props.footerText || "");

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("image");
  }, [url, text]);

  if (!url) {
    return null;
  }
  return (
    <Screen align="center" color={color}>
      <TruthAnalyticsLogo width="50%" />
      <Stack grow align="center" justify="center">
        <img src={url} alt="" style={{ width: "80%" }} />
        <Text center color="primary">
          {text}
        </Text>
        <Text center color="normal" size="2">
          {footerText}
        </Text>
      </Stack>
    </Screen>
  );
};

export default Image;
