import * as React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "./toucan/src/store";

import { useTranslation } from "react-i18next";
import { useInterval } from "./toucan/src/utils";

import { Text, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

const instructions = [
  ["not-muted", "green"],
  ["turn-up-volume", "pink"],
  ["keep-awake", "blue"],
];

const Waiting = () => {
  const { t } = useTranslation("toucan");
  const { part, amountOfParts } = useSelector(
    ({ part, amountOfParts }: IAppState) => ({
      part,
      amountOfParts,
    })
  );
  const [instructionIndex, setInstructionIndex] = React.useState(0);
  useInterval(() => {
    const next = (instructionIndex + 1) % instructions.length;
    setInstructionIndex(next);
  }, 5000);

  const [key, textColor] = instructions[instructionIndex];
  const text = t(key, { part, amount: amountOfParts });
  return (
    <Screen align="center" color={textColor}>
      <Stack grow justify="center">
        <TruthAnalyticsLogo width="100%" />
        <Text center color={"secondary"}>
          {text}
        </Text>
      </Stack>
    </Screen>
  );
};

export default Waiting;
