import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";

import { Text, Button, Stack } from "@smartphoneorchestra/components";
import { Screen, TruthAnalyticsLogo } from "./Styles/StyleComponents";

interface GateProps {
  text: string;
  nextInterface: string;
  buttonText: string;
}

const EndInstructions = (props: GateProps) => {
  const { t } = useTranslation("thecircleoftruth");
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);
  const personalityColor = useInterpolatedString("${this.user.color}");
  const personalityGroup = useInterpolatedString("${this.user.group}");
  const buttonText = useInterpolatedString(props.buttonText);

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = personalityColor;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [personalityColor]);

  return (
    <Screen align="center" background={personalityColor} color="black">
      <TruthAnalyticsLogo width="50%" />
      <Stack grow justify="center">
        <Text center size="4" underline color="primary">
          {t("you-belong-to")}
          <br />
          {t(personalityGroup)}
        </Text>
        <Text center color="primary">
          {text}
        </Text>
      </Stack>
      <Button onClick={onClick}>{buttonText}</Button>
    </Screen>
  );
};

export default EndInstructions;
